<!-- 还没到全 入库 -->
<template>
	<div class="yu_box_1">
		<div>
			
			
			
			 
			 
	 
		  
			 
			 
		 
		 
 
		 
			 
			 <br /><el-row>
			 	<el-col class="yu_input_label" :span="8">
			 		单位名称:
			 	</el-col>
			 	<el-col :span="16"> 
			 		<el-input v-model="shoukuan_info.xt_sk_danwei"></el-input>
			 	</el-col>
			 </el-row>
			 <br /> 
			 <br /><el-row>
			 	<el-col class="yu_input_label" :span="8">
			 		单位地址:
			 	</el-col>
			 	<el-col :span="16"> 
			 		<el-input v-model="shoukuan_info.xt_sk_dizhi"></el-input>
			 	</el-col>
			 </el-row>
			 <br /> 
			 <br /><el-row>
			 	<el-col class="yu_input_label" :span="8">
			 		税号:
			 	</el-col>
			 	<el-col :span="16"> 
			 		<el-input v-model="shoukuan_info.xt_sk_shuihao"></el-input>
			 	</el-col>
			 </el-row>
			 <br /> 
			 <br /><el-row>
			 	<el-col class="yu_input_label" :span="8">
			 		开户银行:
			 	</el-col>
			 	<el-col :span="16"> 
			 		<el-input v-model="shoukuan_info.xt_sk_yinhang"></el-input>
			 	</el-col>
			 </el-row>
			 <br /> 
			 <br /><el-row>
			 	<el-col class="yu_input_label" :span="8">
			 		账号:
			 	</el-col>
			 	<el-col :span="16"> 
			 		<el-input v-model="shoukuan_info.xt_sk_zhanghao"></el-input>
			 	</el-col>
			 </el-row>
			 <br /> 
			 
			 <br /><el-row>
			 	<el-col class="yu_input_label" :span="8">
			 		联系人:
			 	</el-col>
			 	<el-col :span="16"> 
			 		<el-input v-model="shoukuan_info.xt_sk_lianxiren"></el-input>
			 	</el-col>
			 </el-row>
			 <br /> 
			 <br /><el-row>
			 	<el-col class="yu_input_label" :span="8">
			 		电话:
			 	</el-col>
			 	<el-col :span="16"> 
			 		<el-input v-model="shoukuan_info.xt_sk_dianhua"></el-input>
			 	</el-col>
			 </el-row>
			 <br /> 
		  
			<el-row class="yu_content_right">
				<el-button @click="shangpin_set()" plain>点击提交</el-button>
			</el-row>
		</div>
	</div> 
</template>

<script>
	import shangpin_set_get from "./expand/shangpin_set_get.js";
	import shangpin_set_post from "./expand/shangpin_set_post.js";

	export default {

		props: ["m_shpi_id", "parent"],
		data() {
			return {
			 
				shoukuan_info:[ ] 
			}
		},
		created() {
			const that = this;
			that.shangpin_set_get = new shangpin_set_get(that);
			that.shangpin_set_get.m_main();
		},
		methods: { 
			shangpin_set() {
				const that = this;
				that.shangpin_set_post = new shangpin_set_post(that);
				that.shangpin_set_post.m_main();
			},
			handleAvatarSuccess_0(res, file) {
				const that = this;
				console.log("dd");
				console.log(res.upload_file);

				that.m_hangqing_img_2 = res.img_val;
				this.m_hangqing_img_1 = URL.createObjectURL(file.raw);
			},
			quxiao_img_1() {
				const that = this;
			 
				that.m_hangqing_img_1 = ""; 
				that.m_hangqing_img_2 = "";
			},
		 
			beforeAvatarUpload(file) {
				// const isJPG = file.type === 'image/jpeg';
				// const isLt2M = file.size / 1024 / 1024 < 2;

				// if (!isJPG) {
				// 	this.$message.error('上传头像图片只能是 JPG 格式!');
				// }
				// if (!isLt2M) {
				// 	this.$message.error('上传头像图片大小不能超过 2MB!');
				// }
				return true;
			},
			m_fenlei_change(){
				const that = this; 
			},
		}
	}
</script>


<style>
	.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 178px;
		height: 178px;
		line-height: 178px;
		text-align: center;
	}

	.avatar {
		width: 178px;
		height: 178px;
		display: block;
	}
</style>
