<!-- 还没到全 入库 -->
<template>
	<div class="yu_box_1">
		<div>


			<br />
			<el-row>
				<el-col class="yu_input_label" :span="6">
					订单id:
				</el-col>
				<el-col :span="18">
					<el-input v-model="m_baojia_info.tugo_id" disabled></el-input>
				</el-col>
			</el-row>
			<br />
			
			<el-row>
				<el-col class="yu_input_label" :span="6">
					商品id
				</el-col>
				<el-col :span="18">
					<el-input v-model="m_baojia_info.tugo_shpi_id" disabled ></el-input>
				</el-col>
			</el-row>
			<br />
			
			<el-row>
				<el-col class="yu_input_label" :span="6">
					商品名
				</el-col>
				<el-col :span="18">
					<el-input v-model="m_baojia_info.tugo_shangpin_name" disabled ></el-input>
				</el-col>
			</el-row>
			<br />
			
			 
			
			<el-row>
				<el-col class="yu_input_label" :span="6">
					购买数量(吨):
				</el-col>
				<el-col :span="18">
					<el-input v-model="m_baojia_info.tugo_shuliang" disabled ></el-input>
				</el-col>
			</el-row>
			<br />
			 
			<el-row>
				<el-col class="yu_input_label" :span="6">
					查看合同:
				</el-col>
				<el-col :span="18">
					<a class="yu_input_label"  target="_blank" :href="m_baojia_info.tugo_hetong">点击下载</a>
				</el-col>
			</el-row>
			<br />
			  
			<el-row>
				<el-col class="yu_input_label" :span="6">
					下单公司:
				</el-col>
				<el-col :span="18">
					<el-input v-model="m_baojia_info.tugo_gongsi" disabled ></el-input>
				</el-col>
			</el-row>
			<br />
			
			<el-row>
				<el-col class="yu_input_label" :span="6">
					收货地址:
				</el-col>
				<el-col :span="18">
					<el-input v-model="m_baojia_info.tugo_dizhi" disabled ></el-input>
				</el-col>
			</el-row>
			<br />
			
			<el-row>
				<el-col class="yu_input_label" :span="6">
					收货人:
				</el-col>
				<el-col :span="18">
					<el-input v-model="m_baojia_info.tugo_lianxiren" disabled ></el-input>
				</el-col>
			</el-row>
			<br />
			
			 <el-row>
			 	<el-col class="yu_input_label" :span="6">
			 		收货人手机号:
			 	</el-col>
			 	<el-col :span="18">
			 		<el-input v-model="m_baojia_info.tugo_shouji" disabled ></el-input>
			 	</el-col>
			 </el-row>
			 <br />
			 
			 <el-row>
			 	<el-col class="yu_input_label" :span="6">
			 		商品金额:
			 	</el-col>
			 	<el-col :span="18">
			 		<el-input v-model="m_baojia_info.tugo_jine_shangpin" disabled ></el-input>
			 	</el-col>
			 </el-row>
			 <br />
			 
			 <el-row>
			 	<el-col class="yu_input_label" :span="6">
			 		运费金额:
			 	</el-col>
			 	<el-col :span="18">
			 		<el-input v-model="m_baojia_info.tugo_jine_yunfei" disabled ></el-input>
			 	</el-col>
			 </el-row>
			 <br />
			 
			 <el-row>
			 	<el-col class="yu_input_label" :span="6">
			 		物流险金额:
			 	</el-col>
			 	<el-col :span="18">
			 		<el-input v-model="m_baojia_info.tugo_jine_wuliuxian" disabled ></el-input>
			 	</el-col>
			 </el-row>
			 <br />
			 
			 <el-row>
			 	<el-col class="yu_input_label" :span="6">
			 		质量险金额:
			 	</el-col>
			 	<el-col :span="18">
			 		<el-input v-model="m_baojia_info.tugo_jine_zhiliangxian" disabled ></el-input>
			 	</el-col>
			 </el-row>
			 <br />
			 
			 <el-row>
			 	<el-col class="yu_input_label" :span="6">
			 		总金额:
			 	</el-col>
			 	<el-col :span="18">
			 		<el-input v-model="m_baojia_info.tugo_jine_zongjine" disabled ></el-input>
			 	</el-col>
			 </el-row>
			 <br />
			  
			  <el-row>
			  	<el-col class="yu_input_label" :span="6">
			  		发货仓:
			  	</el-col>
			  	<el-col :span="18">
			  		<el-input v-model="m_baojia_info.tugo_fahuocanng" disabled ></el-input>
			  	</el-col>
			  </el-row>
			  <br />
			  
			  <el-row>
			  	<el-col class="yu_input_label" :span="6">
			  		距离(Km):
			  	</el-col>
			  	<el-col :span="18">
			  		<el-input v-model="m_baojia_info.tugo_fahuo_juli" disabled ></el-input>
			  	</el-col>
			  </el-row>
			  <br />
			  
			 <el-row>
			 	<el-col class="yu_input_label" :span="6">
			 		订单状态:
			 	</el-col>
			 	<el-col :span="18">
			 		<el-select v-model="m_baojia_info.tugo_state"    placeholder="请选择" style="width: 100%;">
			 			<el-option v-for="item in m_state_list" :key="item.state_id" :label="item.state_name"
			 				:value="item.state_id">
			 			</el-option>
			 		</el-select>
			 	</el-col>
			 </el-row>
			 <br />
			 
			 

			<br />
			<el-row class="yu_content_right">
				<el-button @click="my_dingdan_info_set" plain>点击提交</el-button>
			</el-row>
		</div>
	</div>
</template>

<script>
	import dingdan_info_get from "./expand/dingdan_info_get.js";
	import dingdan_info_post from "./expand/dingdan_info_post.js";

	export default {

		props: ["m_ding_id", "parent"],
		data() {
			return {
				m_baojia_info: [],
				m_zongjia : 0, 
				
				 
				m_state_list:[{
					state_id : 0,
					state_name : "待支付"
				},{
					state_id : 1,
					state_name : "已支付"
				},{
					state_id : 2,
					state_name : "已发货"
				},{
					state_id : 3,
					state_name : "已退款"
				},{
					state_id : 4,
					state_name : "已完成"
				} ]

			}
		},
		created() {
			const that = this;

			console.log("3333");
			console.log(that.m_ding_id);

			that.dingdan_info_get = new dingdan_info_get(that);
			that.dingdan_info_get.m_main();
		},
		methods: {
			my_dingdan_info_set() {
				const that = this;
				that.dingdan_info_post = new dingdan_info_post(that);
				that.dingdan_info_post.m_main();
			},
			my_danjia_input(event){
				const that = this;
				console.log(event); 
				// this.inputValue = event.target.value;
				
				// return 0;
				that.m_zongjia = 0;
				for (let i = 0;i <that.m_baojia_info.length;i++) {
					that.m_zongjia +=  that.m_baojia_info[i]["diit_no"] * parseFloat(that.m_baojia_info[i]["diit_jiage"])
				}
			}
		}
	}
</script>


<style>
	.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 178px;
		height: 178px;
		line-height: 178px;
		text-align: center;
	}

	.avatar {
		width: 178px;
		height: 178px;
		display: block;
	}
</style>