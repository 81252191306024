import request from "tools/request.js"

//商品设置 提交
class tuangou_jiage_post {
	constructor(parent) {
		this.parent = parent
	}

	m_main() {
		const that = this;


		let tixian_info = that.parent.tixian_info; 
		let session = localStorage.getItem("session");

		let params = {
			tixian_info: tixian_info, 
			session: session
		}

		request.post('/tuangou/tuangou_jiage_info_post', params).then(res => {
			if (res.data.state == 200) {
				this.parent.$notify({
					title: '成功',
					message: res.data.message,
					type: 'success' 
				});
			} else {
				this.parent.$notify({
					title: '失败',
					message: res.data.message,
					type: 'error'
				});
			}
			that.parent.parent.drawer_2 = false;
			that.parent.parent.news_get.m_main();
		})

	}
}

export default tuangou_jiage_post