import request from "tools/request.js"

//商品设置 获取
class news_set_get {
	constructor(parent) {
	    this.parent = parent
	}
	
	m_main() {
		const that = this;
	 
		 let m_news_id 		 	 = that.parent.m_news_id;
		  let session = localStorage.getItem("session");
		 let params = {
		 	m_news_id : m_news_id,
			session : session
		 } 
		 
		request.post('/news/news_set_get',params).then(res => { 
			  
			that.parent.news_name = res.data.m_jituan_info["news_name"];  
			that.parent.html = res.data.m_jituan_info["news_text"]; 
			that.parent.news_jituan_id = res.data.m_jituan_info["news_jituan_id"]; 
			that.parent.news_dingzhi = res.data.m_jituan_info["news_dingzhi"]; 
			that.parent.news_type = res.data.m_jituan_info["news_type"]; 
			    
			console.log(that.parent.m_news_info);
		})
		 
	}
}

export default news_set_get