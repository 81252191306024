import request from "tools/request.js"

//商品设置 提交
class shangpin_set_post {
	constructor(parent) {
		this.parent = parent
	}

	m_main() {
		const that = this;


		let utix_state = that.parent.tixian_info.utix_state;
		let utix_id = that.parent.tixian_info.utix_id;
		let session = localStorage.getItem("session");

		let params = {
			utix_state: utix_state,
			utix_id: utix_id,
			session: session
		}

		request.post('/tixian/tixian_set_post', params).then(res => {
			if (res.data.state == 200) {
				this.parent.$notify({
					title: '成功',
					message: res.data.message,
					type: 'success' 
				});
			} else {
				this.parent.$notify({
					title: '失败',
					message: res.data.message,
					type: 'error'
				});
			}
			that.parent.parent.drawer_2 = false;
			that.parent.parent.news_get.m_main();
		})

	}
}

export default shangpin_set_post