<template>
	<div>
		<layout>

			<br />
			<el-row :gutter="20">

				<el-col :span="8">
					<el-col :span="8"><label class="yu_input_label">新闻名称:</label>
					</el-col>
					<el-col :span="16">
						<el-input v-model="m_shpi_name" @input="m_input_change" placeholder="请输入内容" clearable>
						</el-input>
					</el-col>
				</el-col> 
				<el-col :span="16">
					<el-button class="yu_right_1" @click="drawer_1 = true" plain>
						<i class="el-icon-plus"></i>添加新闻
					</el-button>
				</el-col>
			</el-row> 
			<br />


			<br />
			<el-table :data="m_news_list" style="width: 100%">
				<el-table-column prop="shpl_id" label="id">
				</el-table-column>
				<el-table-column prop="shpl_shangpin_id" label="商品id">
				</el-table-column>  
				<el-table-column prop="shpl_user_id" label="用户id">
				</el-table-column>  
				<el-table-column prop="shpl_time" label="时间">
				</el-table-column>  
				<el-table-column prop="shpl_neirong" label="介绍">
				</el-table-column>  
				<el-table-column label="操作" show-overflow-tooltip align="right">
					<template slot-scope="scope">
						 <el-button size="mini" type="danger" @click="f_news_set(scope.row.shpl_id )">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
			
			<br />
			<br />
			
			<div class="yu_content_right">
				<el-pagination background @current-change="page_current_change" layout="prev, pager, next"
					:page-size="page_size" :total="max_page">
				</el-pagination>
			</div>
			<br />
		</layout>
		<el-drawer title="添加新闻" :visible.sync="drawer_1" size="600px">
			<news_add :parent="me"></news_add>
		</el-drawer>

		<el-drawer title="修改新闻" :visible.sync="drawer_2" size="600px">
			<news_set :key="m_news_id" :m_news_id="m_news_id" :parent="me"></news_set>
		</el-drawer>
	</div>
</template>

<script>
	import layout from 'views/layout.vue'

	import news_add from './components/news_add/news_add'
	import news_set from './components/news_set/news_set'
	import news_get from "./expand/news_get.js"
	import news_delete from "./expand/news_delete.js"
 

	export default {
		components: {
			layout,
			news_add,
			news_set
		},
		data() {
			return {
				me: this,
				m_shpi_name: "",
				m_news_list: [],
				drawer_1: false,
				drawer_2: false,
				checkedNames: [],
				page: 1,
				page_size: 10,
				max_page: 10,
				m_news_id : 0,
				m_shpi_shan_id : ""
			}
		},
		created() {
			const that = this;
			if(this.$route.query.shangjia_id >0){
				that.m_shpi_shan_id = this.$route.query.shangjia_id;
			}
			  
			that.news_get = new news_get(that);
			that.news_get.m_main(); 
			
			that.news_delete = new news_delete(that);
			  
		},
		methods: {

			toggleSelection(rows) {
				if (rows) {
					rows.forEach(row => {
						this.$refs.multipleTable.toggleRowSelection(row);
					});
				} else {
					this.$refs.multipleTable.clearSelection();
				}
			},
			handleSelectionChange(val) {
				this.multipleSelection = val;
			}, 
			page_current_change(val) {
				//翻页
				const that = this;

				that.page = val;
				that.news_get.m_main();

				document.documentElement.scrollTop = document.body.scrollTop = 0;
			},
			m_input_change(val) {
				const that = this;
				
				that.news_get.m_main();
			},
			f_news_set(m_news_id) {
				const that = this; 
				console.log("123123");
				 that.news_delete.m_main(m_news_id); 
			}
		}
	}
</script>
