import request from "tools/request.js"

class news_delete {
	constructor(parent) {
	    this.parent = parent
	}
	
	m_main(m_news_id) {
		const that = this;
		 
		let session = localStorage.getItem("session");
		
		let params = { 
			m_news_id : m_news_id ,
			session : session
		} 
		
		request.post('/pinglun/pinglun_delete_post',params).then(res => {
			if(res.data.state == 902){
				this.parent.$notify({
					title: '提示',
					message: "登陆过期 即将跳转到 登陆",
					type: 'success'
				});
				
				setTimeout(function (){ 
				    that.parent.$router.push({path:'/login',query:{}}); 
				}, 2000); 
			}
			
			this.parent.$notify({
				title: '提示',
				message: "删除成功",
				type: 'success'
			});
			 
			that.parent.news_get.m_main(); 
		})
		 
	}
}

export default news_delete