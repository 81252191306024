import request from "tools/request.js"

//商品设置 提交
class shangpin_set_post {
	constructor(parent) {
		this.parent = parent
	}

	m_main() {
		const that = this;


		let shoukuan_info = that.parent.shoukuan_info; 
	 
		let session = localStorage.getItem("session");

		let params = {
			shoukuan_info: shoukuan_info, 
			session: session
		}

		request.post('/xitong/shoukuan_info_post', params).then(res => {
			if (res.data.state == 200) {
				this.parent.$notify({
					title: '成功',
					message: res.data.message,
					type: 'success'
				});
			} else {
				this.parent.$notify({
					title: '失败',
					message: res.data.message,
					type: 'error'
				});
			}
			that.parent.parent.drawer_2 = false;
		})

	}
}

export default shangpin_set_post