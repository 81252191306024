<template>
	<div>
		<layout>

			<br />
			 
			<br />


			<br />
			<el-table :data="m_news_list" style="width: 100%">
				<el-table-column prop="news_id" label="id">
				</el-table-column>
				<el-table-column prop="news_name" label="项目">
				</el-table-column>   
				<el-table-column label="操作" show-overflow-tooltip align="right">
					<template slot-scope="scope">
						 <el-button size="mini" type="danger" @click="f_news_set(scope.row.news_id )">修改</el-button>
					</template>
				</el-table-column>
			</el-table>
			
			<br />
			<br />
			
			<div class="yu_content_right">
				<el-pagination background @current-change="page_current_change" layout="prev, pager, next"
					:page-size="page_size" :total="max_page">
				</el-pagination>
			</div>
			<br />
		</layout>
		<el-drawer title="行情设置" :visible.sync="drawer_1" size="600px">
			<hangqing_set :parent="me"></hangqing_set>
		</el-drawer>

		<el-drawer title="收款设置" :visible.sync="drawer_2" size="600px">
			<shoukuan_set    :parent="me"></shoukuan_set>  
		</el-drawer>
	</div> 
</template>

<script>
	import layout from 'views/layout.vue'

	import news_add from './components/news_add/news_add'
	import news_set from './components/news_set/news_set'
	import news_get from "./expand/news_get.js"
	import hangqing_set from './components/hangqing_set/hangqing_set'
	import shoukuan_set from './components/shoukuan_set/shoukuan_set'
 

	export default {
		components: {
			layout,
			news_add,
			hangqing_set,
			shoukuan_set
		},
		data() {
			return {
				me: this,
				m_shpi_name: "",
				m_news_list: [],
				drawer_1: false,
				drawer_2: false,
				checkedNames: [],
				page: 1,
				page_size: 10,
				max_page: 10,
				m_news_id : 0,
				m_shpi_shan_id : "",
				m_news_list : [{
					"news_id" : 1,
					"news_name" : "行情波动"
				},
				{
					"news_id" : 2,
					"news_name" : "收款信息"
				}]
			}
		},
		created() {
			const that = this;
			  
			   
			// that.news_get = new news_get(that);
			// that.news_get.m_main();   
		},
		methods: {

			toggleSelection(rows) {
				if (rows) {
					rows.forEach(row => {
						this.$refs.multipleTable.toggleRowSelection(row);
					});
				} else {
					this.$refs.multipleTable.clearSelection();
				}
			},
			handleSelectionChange(val) {
				this.multipleSelection = val;
			}, 
			page_current_change(val) {
				//翻页
				const that = this;

				that.page = val;
				that.news_get.m_main();

				document.documentElement.scrollTop = document.body.scrollTop = 0;
			},
			m_input_change(val) {
				const that = this;
				
				that.news_get.m_main();
			},
			f_news_set(m_news_id) {
				const that = this;
				
				if(m_news_id == 1){ 
					that.drawer_1 = true; 
				}
				if(m_news_id == 2){
					that.drawer_2 = true; 
				}
				  
			}
		}
	}
</script>
