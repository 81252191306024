import request from "tools/request.js"

//商品设置 获取
class shangpin_set_get {
	constructor(parent) {
		this.parent = parent
	}

	m_main() {
		const that = this;


		let session = localStorage.getItem("session");
		let params = {
			session: session
		}

		request.post('/xitong/shoukuan_info_get', params).then(res => { 
			that.parent.shoukuan_info = res.data.shoukuan_info;  
		})

	}
} 

export default shangpin_set_get