import request from "tools/request.js"

//商品设置 获取
class tuangou_jiage_get {
	constructor(parent) {
	    this.parent = parent
	}
	
	m_main() {
		const that = this;
	 
		 let m_news_id 		 	 = that.parent.m_news_id;
		  let session = localStorage.getItem("session");
		 let params = {
		 	m_news_id : m_news_id,
			session : session
		 } 
		 
		request.post('/tuangou/tuangou_jiage_info_get',params).then(res => { 
			  
			that.parent.tixian_info = res.data.result;  
		 
			    
			console.log(that.parent.m_news_info);
		})
		 
	}
}

export default tuangou_jiage_get