<template>
	<div>

		<el-tabs v-model="editableTabsValue" type="card" >

			<el-tab-pane v-for="(item, index) in editableTabs" @click="m_click(item.name2)" :key="item.name" :label="item.title" :name="item.name"   >
				<span slot="label" @click="m_click(item.name2)">
					<!-- <el-tooltip class="item" effect="dark" content="提示 " placement="bottom-start"> -->
						<span class="span-box"  >
							<span>{{item.title}}</span> 
						</span>
					<!-- </el-tooltip> -->
				</span> 
			</el-tab-pane>


		</el-tabs>
	</div>
</template>

<script>
	import router from 'tools/router.js'
	import authority from 'tools/authority' 
	import num_get from './tabs/num_get.js'
	 
	export default {
		data() {
			return {
				editableTabs: "",
				editableTabsValue: '1',  
				quanxian:[],
				tabs: {
					"announcement": [{
						title: '公告',
						name: "1",
						name2: '/',
						no: '0',
						content: '',
						authority:"99999999"
					} ], 
					"dingdan": [{
						title: '订单管理',
						name: "1",
						name2: '/dingdan',
						content: '', 
					}],  
					"shangpin": [{
						title: '商品管理',
						name: "1",
						name2: '/shangpin',
						content: '', 
					} ],    
					"jituan": [{
						title: '集团管理',
						name: "1",
						name2: '/jituan',
						content: '', 
					} ],  
					"pinpai": [{
						title: '品牌管理',
						name: "1",
						name2: '/pinpai',
						content: '', 
					} ],  
					"huiyuan": [{
						title: '会员管理',
						name: "1",
						name2: '/huiyuan',
						content: '', 
					}],   
					"youhuiquan": [{
						title: '优惠券',
						name: "1",
						name2: '/youhuiquan',
						content: '', 
					}],
					"pinglun": [{
						title: '评论管理',
						name: "1",
						name2: '/pinglun',
						content: '', 
					}],
					"news": [{
						title: '新闻管理',
						name: "1",
						name2: '/news',
						content: '', 
					}],  
					"xinxi": [{
						title: '信息管理',
						name: "1",
						name2: '/xinxi',
						content: '', 
					}],
					"yangpin": [{
						title: '样品订单管理',
						name: "1",
						name2: '/yangpin',
						content: '', 
					}],  
					"tuangou": [{
						title: '团购订单管理',
						name: "1",
						name2: '/tuangou',
						content: '', 
					}],  
					"tuangou_jiage": [{
						title: '团购价格管理',
						name: "1",
						name2: '/tuangou_jiage',
						content: '', 
					}], 
					"tixian": [{
						title: '推广提现管理',
						name: "1",
						name2: '/tixian',
						content: '', 
					}], 
					"xitong": [{
						title: '系统管理',
						name: "1",
						name2: '/xitong',
						content: '', 
					}], 
					 
				},
				tabIndex: 2
			}
		},
		created() {
			const that = this;
			for (let item in this.tabs) {
				for (let item2 in this.tabs[item]) { 
					if (this.$route.path == this.tabs[item][item2]["name2"]) { 
						 
						that.editableTabs = this.tabs[item] 
						that.editableTabsValue = parseInt(item2) + 1 + "";
						
						//获取标题后 数量
						that.num_get = new num_get(that);
						that.num_get.m_main(item);
					};
				}
			}
			 
			
		},
		methods: { 
			m_click(oo){
				const that = this;
				router.push({
					path: oo
				})
			}
		}
	}
</script>
