<!-- 还没到全 入库 -->
<template>
	<div class="yu_box_1">
		<div>
			<br />







			<el-row>
				<el-col class="yu_input_label" :span="8">
					品牌id:
				</el-col>
				<el-col :span="16">

					<el-select v-model="shpi_pinpai_id" @change="m_fenlei_change" placeholder="请选择"
						style="width: 100%;">
						<el-option v-for="item in m_pinpai_list" :key="item.pipa_id" :label="item.pipa_name"
							:value="item.pipa_id">
						</el-option>
					</el-select>
				</el-col>
			</el-row>
			<br />

			<el-row>
				<el-col class="yu_input_label" :span="8">
					型号:
				</el-col>
				<el-col :span="16">
					<el-input v-model="shpi_xinghao"></el-input>
				</el-col>
			</el-row>
			<br />

			<el-row>
				<el-col class="yu_input_label" :span="8">
					简介:
				</el-col>
				<el-col :span="16">
					<el-input v-model="shpi_jianjie"></el-input>
				</el-col>
			</el-row>
			<br />

			<el-row>
				<el-col class="yu_input_label" :span="8">
					介绍:
				</el-col>
				<el-col :span="16">
					<el-input v-model="shpi_jieshao"></el-input>
				</el-col>
			</el-row>
			<br />


			<el-row>
				<el-col class="yu_input_label" :span="8">
					封面:
				</el-col>
				<el-col :span="16">
					<el-upload class="avatar-uploader" action="http://106.75.13.203:4035/upload" :show-file-list="false"
						:on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
						<img v-if="m_img" :src="m_img" class="avatar">
						<i v-else class="el-icon-plus avatar-uploader-icon"></i>
					</el-upload>

					<button v-if="m_img" @click="quxiao_img">x</button>
				</el-col>
			</el-row>
			<br />

			<el-row>
				<el-col class="yu_input_label" :span="8">
					详情图1:
				</el-col>
				<el-col :span="16">
					<el-upload class="avatar-uploader" action="http://106.75.13.203:4035/upload" :show-file-list="false"
						:on-success="handleAvatarSuccess_1" :before-upload="beforeAvatarUpload">
						<img v-if="m_img_1" :src="m_img_1" class="avatar">
						<i v-else class="el-icon-plus avatar-uploader-icon"></i>
					</el-upload>
					<button v-if="m_img_1" @click="quxiao_img_1">x</button>
				</el-col>
			</el-row>
			<br />
			<el-row>
				<el-col class="yu_input_label" :span="8">
					详情图2:
				</el-col>
				<el-col :span="16">
					<el-upload class="avatar-uploader" action="http://106.75.13.203:4035/upload" :show-file-list="false"
						:on-success="handleAvatarSuccess_2" :before-upload="beforeAvatarUpload">
						<img v-if="m_img_2" :src="m_img_2" class="avatar">
						<i v-else class="el-icon-plus avatar-uploader-icon"></i>
					</el-upload>
					<button v-if="m_img_2" @click="quxiao_img_2">x</button>
				</el-col>
			</el-row>
			<br />
			<el-row>
				<el-col class="yu_input_label" :span="8">
					详情图3:
				</el-col>
				<el-col :span="16">
					<el-upload class="avatar-uploader" action="http://106.75.13.203:4035/upload" :show-file-list="false"
						:on-success="handleAvatarSuccess_3" :before-upload="beforeAvatarUpload">
						<img v-if="m_img_3" :src="m_img_3" class="avatar">
						<i v-else class="el-icon-plus avatar-uploader-icon"></i>
					</el-upload>
					<button v-if="m_img_3" @click="quxiao_img_3">x</button>
				</el-col>
			</el-row>
			<br />
			<el-row>
				<el-col class="yu_input_label" :span="8">
					详情图4:
				</el-col>
				<el-col :span="16">
					<el-upload class="avatar-uploader" action="http://106.75.13.203:4035/upload" :show-file-list="false"
						:on-success="handleAvatarSuccess_4" :before-upload="beforeAvatarUpload">
						<img v-if="m_img_4" :src="m_img_4" class="avatar">
						<i v-else class="el-icon-plus avatar-uploader-icon"></i>
					</el-upload>
					<button v-if="m_img_4" @click="quxiao_img_4">x</button>
				</el-col>
			</el-row>
			<br />
			<el-row>
				<el-col class="yu_input_label" :span="8">
					详情图5:
				</el-col>
				<el-col :span="16">
					<el-upload class="avatar-uploader" action="http://106.75.13.203:4035/upload" :show-file-list="false"
						:on-success="handleAvatarSuccess_5" :before-upload="beforeAvatarUpload">
						<img v-if="m_img_5" :src="m_img_5" class="avatar">
						<i v-else class="el-icon-plus avatar-uploader-icon"></i>
					</el-upload>
					<button v-if="m_img_5" @click="quxiao_img_5">x</button>
				</el-col>
			</el-row>
			<br />


			<el-row>
				<el-col class="yu_input_label" :span="8">
					评分:
				</el-col>
				<el-col :span="16">
					<el-input v-model="shpi_pingfen"></el-input>
				</el-col>
			</el-row>
			<br />

			<el-row>
				<el-col class="yu_input_label" :span="8">
					点赞:
				</el-col>
				<el-col :span="16">
					<el-input v-model="shpi_dianzan"></el-input>
				</el-col>
			</el-row>
			<br />


			<el-row>
				<el-col class="yu_input_label" :span="8">
					评论:
				</el-col>
				<el-col :span="16">
					<el-input v-model="shpi_pinglun"></el-input>
				</el-col>
			</el-row>
			<br />

			<el-row>
				<el-col class="yu_input_label" :span="8">
					排序:
				</el-col>
				<el-col :span="16">
					<el-input v-model="shpi_paixu"></el-input>
				</el-col>
			</el-row>
			<br />

			<el-row>
				<el-col class="yu_input_label" :span="8">
					分类1:
				</el-col>
				<el-col :span="16">

					<el-select v-model="shpi_fenlei_1" @change="m_fenlei_change" placeholder="请选择" style="width: 100%;">
						<el-option v-for="item in m_fenlei_list_1" :key="item.fele_id" :label="item.fele_name"
							:value="item.fele_id">
						</el-option>
					</el-select>
				</el-col>
			</el-row>
			<br />
			<el-row>
				<el-col class="yu_input_label" :span="8">
					分类2:
				</el-col>
				<el-col :span="16">

					<el-select v-model="shpi_fenlei_2" @change="m_fenlei_change" placeholder="请选择" style="width: 100%;">
						<el-option v-for="item in m_fenlei_list_2" :key="item.fele_id" :label="item.fele_name"
							:value="item.fele_id">
						</el-option>
					</el-select>
				</el-col>
			</el-row>
			<br />
			<el-row>
				<el-col class="yu_input_label" :span="8">
					分类3:
				</el-col>
				<el-col :span="16">

					<el-select v-model="shpi_fenlei_3" @change="m_fenlei_change" placeholder="请选择" style="width: 100%;">
						<el-option v-for="item in m_fenlei_list_3" :key="item.fele_id" :label="item.fele_name"
							:value="item.fele_id">
						</el-option>
					</el-select>
				</el-col>
			</el-row>
			<br />
			<el-row>
				<el-col class="yu_input_label" :span="8">
					分类4:
				</el-col>
				<el-col :span="16">

					<el-select v-model="shpi_fenlei_4" @change="m_fenlei_change" placeholder="请选择" style="width: 100%;">
						<el-option v-for="item in m_fenlei_list_4" :key="item.fele_id" :label="item.fele_name"
							:value="item.fele_id">
						</el-option>
					</el-select>
				</el-col>
			</el-row>
			<br />
			<el-row>
				<el-col class="yu_input_label" :span="8">
					销售单位:
				</el-col>
				<el-col :span="16">
					<el-input v-model="shpi_danwei"></el-input>
				</el-col>
			</el-row>
			<br />
			<el-row>
				<el-col class="yu_input_label" :span="8">
					销量:
				</el-col>
				<el-col :span="16">
					<el-input v-model="shpi_xiaoliang"></el-input>
				</el-col>
			</el-row>
			<br /><el-row>
				<el-col class="yu_input_label" :span="8">
					价格:
				</el-col>
				<el-col :span="16">
					<el-input v-model="shpi_jiage"></el-input>
				</el-col>
			</el-row>
			<br />
			<el-row>
				<el-col class="yu_input_label" :span="8">
					首页推荐:
				</el-col>
				<el-col :span="16">

					<el-select v-model="shpi_tuijian" @change="m_fenlei_change" placeholder="请选择"
						style="width: 100%;">
						<el-option v-for="item in shpi_tuijian_list" :key="item.tuijian_id" :label="item.tuijian_text"
							:value="item.tuijian_id">
						</el-option> 
					</el-select>
				</el-col>
			</el-row>
			<br />
			<el-row class="yu_content_right">
				<el-button @click="shangpin_add" plain>点击提交</el-button>
			</el-row>
		</div>
	</div>
</template>

<script>
	import shangpin_add_post from "./expand/shangpin_add_post.js";
	import shangpin_add_get from "./expand/shangpin_add_get.js";

	export default {

		props: ["parent"],
		data() {
			return {
				m_fenlei_list_1: [],
				m_fenlei_list_2: [],
				m_fenlei_list_3: [],
				m_fenlei_list_4: [],
				m_pinpai_list: [],
				shpi_pinpai_id: "",
				shpi_xinghao: "",
				shpi_jianjie: "",
				shpi_jieshao: "",
				shpi_label_2: "",
				shpi_pingfen: "",
				shpi_dianzan: "",
				shpi_pinglun: "",
				shpi_paixu: "",
				shpi_fenlei_1: "",
				shpi_fenlei_2: "",
				shpi_fenlei_3: "",
				shpi_fenlei_4: "",
				m_img: '',
				m_img2: '',
				m_img_1: '',
				m_img_2: '',
				m_img_3: '',
				m_img_4: '',
				m_img_5: '',
				m_img2_1: "",
				m_img2_2: "",
				m_img2_3: "",
				m_img2_4: "",
				m_img2_5: "",
				shpi_danwei: "",
				shpi_xiaoliang: "",
				shpi_jiage : "",
				shpi_tuijian: 0,
				shpi_tuijian_list: [{
						"tuijian_id": 0,
						"tuijian_text": "不推荐"
					},
					{
						"tuijian_id": 1,
						"tuijian_text": "推荐"
					}
				],
			}
		},
		created() {
			const that = this;
			that.shangpin_add_get = new shangpin_add_get(that);
			that.shangpin_add_get.m_main();
		},
		methods: {
			shangpin_add() {
				const that = this;
				that.shangpin_add_post = new shangpin_add_post(that);
				that.shangpin_add_post.m_main();
			},
			handleAvatarSuccess(res, file) {
				const that = this;
				that.m_img2 = res.img_val;
				this.m_img = URL.createObjectURL(file.raw);
			},
			quxiao_img() {
				const that = this;
				that.m_img = "";
				that.m_img2 = "";
			},
			handleAvatarSuccess_1(res, file) {
				const that = this;
				that.m_img2_1 = res.img_val;
				this.m_img_1 = URL.createObjectURL(file.raw);
			},
			quxiao_img_1() {
				const that = this;
				that.m_img2_1 = "";
				that.m_img_1 = "";
			},
			handleAvatarSuccess_2(res, file) {
				const that = this;
				that.m_img2_2 = res.img_val;
				this.m_img_2 = URL.createObjectURL(file.raw);
			},
			quxiao_img_2() {
				const that = this;
				that.m_img2_2 = "";
				that.m_img_2 = "";
			},
			handleAvatarSuccess_3(res, file) {
				const that = this;
				that.m_img2_3 = res.img_val;
				this.m_img_3 = URL.createObjectURL(file.raw);
			},
			quxiao_img_3() {
				const that = this;
				that.m_img2_3 = "";
				that.m_img_3 = "";
			},
			handleAvatarSuccess_4(res, file) {
				const that = this;
				that.m_img2_4 = res.img_val;
				this.m_img_4 = URL.createObjectURL(file.raw);
			},
			quxiao_img_4() {
				const that = this;
				that.m_img2_4 = "";
				that.m_img_4 = "";
			},
			handleAvatarSuccess_5(res, file) {
				const that = this;
				that.m_img2_5 = res.img_val;
				this.m_img_5 = URL.createObjectURL(file.raw);
			},
			quxiao_img_5() {
				const that = this;
				that.m_img2_5 = "";
				that.m_img_5 = "";
			},
			beforeAvatarUpload(file) {
				const isJPG = file.type === 'image/png';
				const isLt2M = file.size / 1024 / 1024 < 2;

				if (!isJPG) {
					this.$message.error('上传头像图片只能是 PNG 格式!');
				}
				if (!isLt2M) {
					this.$message.error('上传头像图片大小不能超过 2MB!');
				}
				return isJPG && isLt2M;
			},
			m_fenlei_change() {
				const that = this;
			},
		}
	}
</script>


<style>
	.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 178px;
		height: 178px;
		line-height: 178px;
		text-align: center;
	}

	.avatar {
		width: 178px;
		height: 178px;
		display: block;
	}
</style>