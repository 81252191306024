import request from "tools/request.js"

//商品设置 提交
class shangpin_set_post {
	constructor(parent) {
		this.parent = parent
	}

	m_main() {
		const that = this;


		let m_hangqing_text = that.parent.m_hangqing_text;
		let m_hangqing_text2 = that.parent.m_hangqing_text2;
		let m_hangqing_img_1 = that.parent.m_hangqing_img_2;
	 
		let session = localStorage.getItem("session");

		let params = {
			m_hangqing_text: m_hangqing_text,
			m_hangqing_text2: m_hangqing_text2,
			m_hangqing_img_1: m_hangqing_img_1,
			session: session
		}

		request.post('/xitong/hangqing_info_post', params).then(res => {
			if (res.data.state == 200) {
				this.parent.$notify({
					title: '成功',
					message: res.data.message,
					type: 'success'
				});
			} else {
				this.parent.$notify({
					title: '失败',
					message: res.data.message,
					type: 'error'
				});
			}
			that.parent.parent.drawer_1 = false;
		})

	}
}

export default shangpin_set_post