<!-- 还没到全 入库 -->
<template>
	<div class="yu_box_1">
		<div>
			
			
			
			 
			 
	 
		  
			 
			 
			<el-row>
				<el-col class="yu_input_label" :span="8">
					走势图:
				</el-col>
				<el-col :span="16">
					<el-upload class="avatar-uploader" action="http://106.75.13.203:4035/upload" :show-file-list="false"
						:on-success="handleAvatarSuccess_0" :before-upload="beforeAvatarUpload">
						<img v-if="m_hangqing_img_1" :src="m_hangqing_img_1" class="avatar">
						<i v-else class="el-icon-plus avatar-uploader-icon"></i>
					</el-upload>
					<button v-if="m_hangqing_img_1" @click="quxiao_img_1">x</button>
				</el-col>
			</el-row>
			<br />
			  
			 
			 <br /><el-row>
			 	<el-col class="yu_input_label" :span="8">
			 		行情文字1:
			 	</el-col>
			 	<el-col :span="16"> 
			 		<el-input v-model="m_hangqing_text"></el-input>
			 	</el-col>
			 </el-row>
			 <br /> 
			 <br /><el-row>
			 	<el-col class="yu_input_label" :span="8">
			 		行情文字2:
			 	</el-col>
			 	<el-col :span="16"> 
			 		<el-input v-model="m_hangqing_text2"></el-input>
			 	</el-col>
			 </el-row> 
			 <br /> 
			<el-row class="yu_content_right">
				<el-button @click="shangpin_set()" plain>点击提交</el-button>
			</el-row>
		</div>
	</div>
</template>

<script>
	import shangpin_set_get from "./expand/shangpin_set_get.js";
	import shangpin_set_post from "./expand/shangpin_set_post.js";

	export default {

		props: ["m_shpi_id", "parent"],
		data() {
			return {
			 
				m_hangqing_img_1:"",
				m_hangqing_img_2:"",
			 m_hangqing_text : "" ,
			 m_hangqing_text2 : ""
			}
		},
		created() {
			const that = this;
			that.shangpin_set_get = new shangpin_set_get(that);
			that.shangpin_set_get.m_main();
		},
		methods: {
			shangpin_set() {
				const that = this;
				that.shangpin_set_post = new shangpin_set_post(that);
				that.shangpin_set_post.m_main();
			},
			handleAvatarSuccess_0(res, file) {
				const that = this;
				console.log("dd");
				console.log(res.upload_file);

				that.m_hangqing_img_2 = res.img_val;
				this.m_hangqing_img_1 = URL.createObjectURL(file.raw);
			},
			quxiao_img_1() {
				const that = this;
			 
				that.m_hangqing_img_1 = ""; 
				that.m_hangqing_img_2 = "";
			},
		 
			beforeAvatarUpload(file) {
				// const isJPG = file.type === 'image/jpeg';
				// const isLt2M = file.size / 1024 / 1024 < 2;

				// if (!isJPG) {
				// 	this.$message.error('上传头像图片只能是 JPG 格式!');
				// }
				// if (!isLt2M) {
				// 	this.$message.error('上传头像图片大小不能超过 2MB!');
				// }
				return true;
			},
			m_fenlei_change(){
				const that = this; 
			},
		}
	}
</script>


<style>
	.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 178px;
		height: 178px;
		line-height: 178px;
		text-align: center;
	}

	.avatar {
		width: 178px;
		height: 178px;
		display: block;
	}
</style>
