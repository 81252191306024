import request from "tools/request.js"

//商品设置 获取
class shangpin_set_get {
	constructor(parent) {
		this.parent = parent
	}

	m_main() {
		const that = this;


		let session = localStorage.getItem("session");
		let params = {
			session: session
		}

		request.post('/xitong/hangqing_info_get', params).then(res => {



			that.parent.m_hangqing_text = res.data.m_hangqing_text;
			that.parent.m_hangqing_text2 = res.data.m_hangqing_text2;
			that.parent.m_hangqing_img_1 = res.data.m_hangqing_img_1;
			that.parent.m_hangqing_img_2 = res.data.m_hangqing_img_2;

		})

	}
}

export default shangpin_set_get