import request from "tools/request.js"

//建筑分类 添加建筑分类
class jianzhu_fenlei_add_post {
	constructor(parent) {
	    this.parent = parent
	}
	
	m_main() {
		const that = this;
		   
		let session 					= localStorage.getItem("session"); 
		 
	 
 
		let params = { 
			 
			html : that.parent.html, 
			news_name : that.parent.news_name,
			news_jituan_id : that.parent.news_jituan_id,
			m_zhi_text : that.parent.m_zhi_text,
			m_news_type : that.parent.m_news_type,
			news_shangpin_id : that.parent.news_shangpin_id,
			session : session
		} 
		 
		request.post('/news/news_add_post',params).then(res => { 
			if(res.data.state == 200){
				this.parent.$notify({
				  title: '成功',
				  message: res.data.message,
				  type: 'success'
				}); 
			}else{
				this.parent.$notify({
				  title: '失败',
				  message: res.data.message,
				  type: 'error'
				}); 
			}
			that.parent.parent.drawer_1 = false;
			that.parent.parent.news_get.m_main(); 
		})
		 
	}
}

export default jianzhu_fenlei_add_post