<template>
	<el-row class="tac">
		<el-col :span="24">
			<el-menu :default-active="active_index" class="el-menu-vertical-demo" collapse-transition="false"
				:collapseTransition="coll_state">
				
				
				<router-link to="/jituan">
					<el-menu-item index="3"> 
						<i class="el-icon-menu"></i>
						<span slot="title">集团管理</span>
					</el-menu-item> 
				</router-link>
				
				<router-link to="/pinpai">
					<el-menu-item index="4"> 
						<i class="el-icon-menu"></i>
						<span slot="title">品牌管理</span>
					</el-menu-item> 
				</router-link>
				
				<router-link to="/dingdan">
					<el-menu-item index="1"> 
						<i class="el-icon-menu"></i>
						<span slot="title">订单管理</span>
					</el-menu-item> 
				</router-link>
				<router-link to="/yangpin">
					<el-menu-item index="7"> 
						<i class="el-icon-menu"></i>
						<span slot="title">样品订单管理</span>
					</el-menu-item> 
				</router-link>
				<router-link to="/tuangou">
					<el-menu-item index="8"> 
						<i class="el-icon-menu"></i>
						<span slot="title">团购订单管理</span>
					</el-menu-item> 
				</router-link>
				<router-link to="/tuangou_jiage">
					<el-menu-item index="10"> 
						<i class="el-icon-menu"></i>
						<span slot="title">团购价格管理</span>
					</el-menu-item> 
				</router-link>
				<router-link to="/tixian"> 
					<el-menu-item index="9"> 
						<i class="el-icon-menu"></i>
						<span slot="title">推广提现管理</span>
					</el-menu-item> 
				</router-link>
				<router-link to="/shangpin">
					<el-menu-item index="2"> 
						<i class="el-icon-menu"></i>
						<span slot="title">商品管理</span>
					</el-menu-item> 
				</router-link>
				<router-link to="/pinglun">
					<el-menu-item index="11"> 
						<i class="el-icon-menu"></i>
						<span slot="title">评论管理</span>
					</el-menu-item> 
				</router-link>
				<router-link to="/youhuiquan">
					<el-menu-item index="5"> 
						<i class="el-icon-menu"></i>
						<span slot="title">优惠券管理</span>
					</el-menu-item> 
				</router-link>
				<router-link to="/news">
					<el-menu-item index="6"> 
						<i class="el-icon-menu"></i>
						<span slot="title">新闻管理</span>
					</el-menu-item> 
				</router-link>
				
				<!-- <router-link to="/huodong">
					<el-menu-item index="2"> 
						<i class="el-icon-menu"></i>
						<span slot="title">活动管理</span>
					</el-menu-item> 
				</router-link>
				 -->
				 
				 
				 <router-link to="/huiyuan">
				 	<el-menu-item index="14"> 
				 		<i class="el-icon-menu"></i>
				 		<span slot="title">会员管理</span>
				 	</el-menu-item> 
				 </router-link>
				 
				 <router-link to="/xitong">
				 	<el-menu-item index="15"> 
				 		<i class="el-icon-menu"></i>
				 		<span slot="title">系统管理</span>
				 	</el-menu-item> 
				 </router-link>
				 
				<!-- <router-link to="/xinxi">
					<el-menu-item index="5"> 
						<i class="el-icon-menu"></i>
						<span slot="title">信息管理</span>
					</el-menu-item> 
				</router-link> -->
				
			 
		 
			 
				 
			</el-menu>
		</el-col>

	</el-row>
</template>

<script>
	 

	export default {
		data() {
			return {
				"quanxian": [],
				"active_index": "1",
				"sidebars": {  
					"/dingdan": "1",
					"/shangpin": "2", 
					"/jituan": "3",  
					"/pinpai": "4", 
					"/yangpin": "7", 
					"/youhuiquan": "5", 
					"/huiyuan": "14", 
					"/news": "6", 
					"/tuangou": "6", 
					"/tixian": "9",
					"/tuangou_jiage": "10",
					"/pinglun": "11", 
					"/xitong": "15", 
				},
				"coll_state": false
			}
		},
		created() {
			const that = this;
			this.active_index = this.sidebars[this.$route.path]
 
			// let quanxian2 = JSON.parse(localStorage.getItem('quanxian2'));
			// that.quanxian =  quanxian2
		},
		methods: {
			handleOpen(key, keyPath) {
				console.log(key, keyPath);
			},
			handleClose(key, keyPath) {
				console.log(key, keyPath);
			}
		}
	}
</script>

<style>
	.el-submenu__title {
		text-align: left;
	}

	.el-menu-item {
		text-align: left;
	}

	.el-icon-s-marketing {
		margin-top: -4px;
	}
</style>
